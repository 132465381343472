<template>

  <label>

    <slot></slot>

    <span v-if="required" class="pl-1 text-red-700">*</span>

  </label>

</template>

<script>
export default {

  name: 'LabelField',

  props: {

    required: { type: Boolean, default: false }

  }

}
</script>
